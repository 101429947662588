/* eslint-disable @typescript-eslint/quotes */
// import thumb1 from '@/assets/meditation-images/thumb-1.webp';
import thumb1 from '../assets/new-assets/home/meditation-bg.webp';
import thumb2 from '@/assets/meditation-images/thumb-2.webp';
import thumb3 from '@/assets/meditation-images/thumb-3.webp';

export const freeMeditations = [
  {
    id: 398,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://cdn.lvl.fit/d05b5f5a-e0cb-4254-861c-79223ef3cec4.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/d05b5f5a-e0cb-4254-861c-79223ef3cec4.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/d05b5f5a-e0cb-4254-861c-79223ef3cec4.webp`,
    activity_url: `https://cdn.lvl.fit/a18653ae-f0af-4250-8287-ec2eb6d7a511.wav`,
    category: `For Work/Study`,
    language: `Hindi`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Ranveer.webp`,
    style: { gradient: `linear-gradient(#5c289ee0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 399,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://cdn.lvl.fit/61e63b27-9943-452a-b185-6669735694fc.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/61e63b27-9943-452a-b185-6669735694fc.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/61e63b27-9943-452a-b185-6669735694fc.webp`,
    activity_url: `https://cdn.lvl.fit/c94828c0-250c-401b-b9fe-3418905bdc8d.wav`,
    category: `For Work/Study`,
    language: `English`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Ranveer.webp`,
    style: { gradient: `linear-gradient(#9c3c6ae0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 400,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://cdn.lvl.fit/39c569c1-0286-42c6-a2ec-96ed96fff11c.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/39c569c1-0286-42c6-a2ec-96ed96fff11c.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/39c569c1-0286-42c6-a2ec-96ed96fff11c.webp`,
    activity_url: `https://cdn.lvl.fit/9b9de794-cbf5-4f70-9ae8-05517a760282.wav`,
    category: `For Work/Study`,
    language: `Marathi`,
    coach_name: `Deepa`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const manageYourEmotions = [
  {
    id: 1,
    name: `Emotional Pain`,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions`,
    thumbnail_sm_url: `https://cdn.lvl.fit/bda0bff7-00ac-417b-975b-e1f1d2f23852.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/bda0bff7-00ac-417b-975b-e1f1d2f23852.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/bda0bff7-00ac-417b-975b-e1f1d2f23852.webp`,
    activity_url: `https://cdn.lvl.fit/130087f6-78d9-45dd-915e-56a10dafb388.mp3`,
    category: `Manage Your Emotions Series (हिन्दी)`,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 2,
    name: `Relieve Stress`,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions
    `,
    thumbnail_sm_url: `https://cdn.lvl.fit/8ea46de8-51c9-4e7d-9160-6bf0f150b449.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/8ea46de8-51c9-4e7d-9160-6bf0f150b449.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/8ea46de8-51c9-4e7d-9160-6bf0f150b449.webp`,
    activity_url: `https://cdn.lvl.fit/8466721c-e77c-456e-93c6-bb654e401ad9.mp3`,
    category: `Manage Your Emotions Series (हिन्दी)    `,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 3,
    name: `Deal With Anxiety`,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions`,
    thumbnail_sm_url: `https://cdn.lvl.fit/4e1ee2e4-4340-4f62-9cc5-7883c7ad29ed.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/4e1ee2e4-4340-4f62-9cc5-7883c7ad29ed.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/4e1ee2e4-4340-4f62-9cc5-7883c7ad29ed.webp`,
    activity_url: `https://cdn.lvl.fit/86f7be20-eab2-4dd1-8c24-d7368c99965f.mp3`,
    category: `Manage Your Emotions Series (हिन्दी)`,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const mantraSeries = [
  {
    id: 1,
    name: `Day 1 : Meaning of Mantra`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://cdn.lvl.fit/8e92ae7e-fb7c-4467-8c13-036ac4334e50.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/8e92ae7e-fb7c-4467-8c13-036ac4334e50.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/8e92ae7e-fb7c-4467-8c13-036ac4334e50.webp`,
    activity_url: `https://cdn.lvl.fit/048a96b4-daa6-482f-ac55-fbb6c4ffb945.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Om%20Dhumatkar.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 2,
    name: `Day 2 : Science Behind Mantra `,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://cdn.lvl.fit/9261c69d-dab8-4c00-b311-54e3c6e2c430.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/9261c69d-dab8-4c00-b311-54e3c6e2c430.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/9261c69d-dab8-4c00-b311-54e3c6e2c430.webp`,
    activity_url: `https://cdn.lvl.fit/3d3ecb90-8836-4db3-9d49-a6b5e33ddfc9.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Om%20Dhumatkar.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 3,
    name: `Day 3 : Mantras and Spirituality `,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://cdn.lvl.fit/2a0627cd-9f49-4e70-9246-f8d45d58e9e9.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/2a0627cd-9f49-4e70-9246-f8d45d58e9e9.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/2a0627cd-9f49-4e70-9246-f8d45d58e9e9.webp`,
    activity_url: `https://cdn.lvl.fit/58c89d5a-adc7-4b00-91c4-607a38fe241a.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Om%20Dhumatkar.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const meditationReccomendation = [
  {
    id: 430,
    name: `Beat Anxious Thoughts`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://cdn.lvl.fit/39b3109a-ac33-42eb-817c-e9dac50e0cf7.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/39b3109a-ac33-42eb-817c-e9dac50e0cf7.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/39b3109a-ac33-42eb-817c-e9dac50e0cf7.webp`,
    activity_url: `https://cdn.lvl.fit/e7943562-8e5f-45c0-9705-5b1ac392ff5b.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Victoria`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Victoria.webp `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '5mins',
  },
  {
    id: 640,
    name: `Breathwork For Anxiety`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://cdn.lvl.fit/c5f0a3d8-11dd-4d09-8b7c-34e8d7527f17.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/c5f0a3d8-11dd-4d09-8b7c-34e8d7527f17.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/c5f0a3d8-11dd-4d09-8b7c-34e8d7527f17.webp`,
    activity_url: `https://cdn.lvl.fit/3da648aa-3603-468f-a95d-ed9b3d060276.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Ranveer.webp `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '10mins',
  },
];
export const musicReccomendation = [
  {
    id: 369,
    name: `Quick Deep Sleep`,
    thumbnail_sm_url: `https://cdn.lvl.fit/b9cf3b1a-a82a-4178-b327-774fbacf6188.webp`,
    activity_url:
      'https://cdn.lvl.fit/40e1f5e4-0443-45b2-abd0-4b1482bebde3.mp3',
    coach_name: `Level`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '50mins',
  },
  {
    id: 370,
    name: `Raag for Full Body Relaxation`,
    thumbnail_sm_url: `https://cdn.lvl.fit/74cc9b92-7ed2-4bb9-941d-9a5bee32ff72.webp`,
    activity_url:
      'https://cdn.lvl.fit/18d9f47c-ab7b-491f-aa7b-9d94d163ff23.mp3',

    coach_name: `Level`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '45mins',
  },
];

export const workoutReccomendation = [
  {
    id: 1427,
    name: 'Lower Body HIIT',
    thumbnail_sm_url:
      'https://cdn.lvl.fit/24600ac8-3fdb-479a-aad0-0519bd997b74.webp',
    activity_url:
      'https://v3.cdn.level.game/workouts/Videos/HIIT%20Series/Day%203%20HIIT/Day%203%20-%20Hiit%20-%20Lower%20Body%20Focused%20Line%20Up%20Edit%20For%20Qc%204%20CC%20For%20App.m4v',
    coach_name: `Giftin`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Giftin+(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '31mins',
  },
  {
    id: 1650,
    name: 'Day 4: Kegel Exercise',
    thumbnail_sm_url:
      'https://cdn.lvl.fit/78c8b75f-f587-49aa-bf2b-4eb649277e1e.webp',
    activity_url:
      'https://cdn.lvl.fit/8baac0ec-d2e5-44fd-933b-dbd081885e20.mp4',
    coach_name: `Anusha`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/anushaold.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '21mins',
  },
];

export const sleepReccomendation = [
  {
    id: 155,
    name: `Siddhartha Chapter 1`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://cdn.lvl.fit/36203eaf-b4ef-4322-8874-e3ab97333b6e.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/36203eaf-b4ef-4322-8874-e3ab97333b6e.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/36203eaf-b4ef-4322-8874-e3ab97333b6e.webp`,
    activity_url: `https://cdn.lvl.fit/5570f110-689d-4c60-8d0f-5b7f5d5ef3a9.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Level`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '20mins',
  },
  {
    id: 640,
    name: `Lord Krishna's Story (हिन्दी)`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://cdn.lvl.fit/db1f1e53-57a2-45f8-9bee-748666856919.webp`,
    thumbnail_md_url: `https://cdn.lvl.fit/db1f1e53-57a2-45f8-9bee-748666856919.webp`,
    thumbnail_lg_url: `https://cdn.lvl.fit/db1f1e53-57a2-45f8-9bee-748666856919.webp`,
    activity_url: `https://cdn.lvl.fit/a8e79c3d-5d46-48d1-8403-086c9f96ef2c.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Level`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Level%20(1).webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '25mins',
  },
];
